/**
 * External dependencies
 */
import React, { useMemo, useState, useEffect } from "react"
import { Link } from "gatsby"
import axios from "axios";
import { Row, Col, Container } from "react-bootstrap"

/**
 * Internal dependencies
 */
import useHomeProperties from "./use-home-properties"
import { convertGatsbyImageNodes } from "../../utils/gatsby-images"
import Card from "../card/card"
import LinkIcon from "../link-icon/link-icon"
import moment from "moment"
import properties from "./static/properties"
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
import "./styles/_index.scss"
import Carousel from "../carousel/carousel"
import classNames from "classnames"
import { currencyFormat } from '@components/common/utils';
import Modal from 'react-bootstrap/Modal';
import BookAViewingForm from "../../Forms/BookAViewing"
import {
  isIOS,
  isMobile
} from "react-device-detect";
import PropertyEnquireForm from "../../Forms/PropertyEnquire";
const AuctionSimilarProperties = (props) => {
  const [showForm, setShowForm] = useState(false);
  const [index, setIndex] = useState();
  const openform = (i) => {
            setShowForm(!showForm)
          setIndex(i)
  }
  const [propItems, setPropItems] = useState([])

  const getitems = async url => {
      try {
          const { data } = await axios.get(url, {baseURL: process.env.GATSBY_STRAPI_FORM_URL,
            headers: {
              Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
              // "Content-Type": "multipart/form-data"  
            }})
          // console.log("PropertySimilar", data)
          setPropItems(data)
      } catch (error) {
          // console.error(error)
      }
  }

  useEffect(() => {
      let url =
      process.env.GATSBY_STRAPI_SRC +
      "/stb-lists/item/Auctions-details?pid=" +
      props?.data?.id

      getitems(url)
  }, [props?.data?.id]);

  const propertiesCollection = useMemo(
    () =>
      propItems.map((x, i) => {
        // console.log(x)
        let uriStr = "auction-property-for-sale/"
        let mysale = ""
        let hit = { ...x }
        // mapping
        hit.searchType = hit.searchType || hit.search_type;

        if (hit.searchType === "sales" && hit.department === "residential") {
          uriStr = `property-for-sale/`
          mysale = `myResSaleProp`
        } else if (
          hit.searchType === "lettings" &&
          hit.department === "residential"
        ) {
          uriStr = `property-to-rent/`
          mysale = `myResRentProp`
        }

        let processedImages = JSON.stringify({});
        if (x.imagetransforms?.images_Transforms) {
          processedImages = x.imagetransforms?.images_Transforms;
        }
        return (
          <Card className="card-let">
    <Link to={`/${uriStr}${hit.slug}-${hit.id}`}>
    <div className="property-box__badge">{x?.status}</div>
      {/* <Card.Head image={image} alt="property" /> */}
      <div className="overflow-hidden">
                <div className="hover-zoom">
      <ImageTransform
                imagesources={x?.images[0]?.url}
                // renderer="srcSet"
                imagename="property.images.similardetail"
                attr={{ alt: `${hit.display_address} - Strakers Estate Agents`, className: "" }}
                imagetransformresult={processedImages}
                id={x.id}
              />
              </div>
              </div>
    </Link>
    <Card.Body>
    <Link to={`/${uriStr}${hit.slug}-${hit.id}`}>
      <Row className="g-0 align-items-center">
        <Col>
        {hit?.extra?.lotNumber &&
          <h3>Lot {hit?.extra?.lotNumber}</h3>
        }
        </Col>
        <Col xs="auto">
          {hit?.extra?.onlineAuction?.EndDate && <p className="fw-bolder non-bottom">{moment(hit?.extra?.onlineAuction?.EndDate).format("DD MMMM YYYY")}</p>}
        </Col>
      </Row>

      <div className="card-let__price">
        <span className="text-uppercase d-inline-block">
          *Guide Price <span className="fw-bolder">{hit?.price=="0"?`£${hit?.price}`:currencyFormat(hit?.price, '£', false)}</span>+ Plus Fees
        </span>
      </div>
      <h4>
        <Link href={`/${uriStr}${hit.slug}-${hit.id}/`}>
          {hit.display_address}
          </Link>
      </h4>
      {hit.title && <p className="card-let__description">{hit.title}</p>}
      </Link>
    </Card.Body>

    <Card.Footer>
      <Row className="g-0 justify-content-between">
        <Col>
          <LinkIcon href={`/${uriStr}${hit.slug}-${hit.id}`} Link_Type={"Internal_url"} text="Details/Bid" icon="eye" />
        </Col>

        <Col>
        {hit.extra?.legalDocumentUrl &&
          <LinkIcon href={`/${uriStr}${hit.slug}-${hit.id}`} Link_Type={"Internal_url"} text="Legal Docs" icon="file" />
        }
        </Col>
      </Row>
    </Card.Footer>
  </Card>
        )
      }),
    [propItems]
  )

  return (
    propItems.length > 0 &&
    <div className="home-properties similar-properties">
      <Container className="home-properties__inner">
        {/* <h6 className="home-properties__quote fw-bolder fz14 d-inline-block">
          {props.Cap_Title}
        </h6> */}

        <Row className="align-items-center align-items-lg-start justify-content-lg-between">
          <Col xs={12} lg="auto">
            <h3>You might also be interested in...</h3>
          </Col>

          {/* <Col lg="auto">
            <Row className="flex-nowrap home-properties__actions">
              <Col xs="auto" className="home-properties__action-col-1">
                <LinkIcon href="/" text="Property for sale" />
              </Col>

              <Col xs="auto">
                <LinkIcon href="/" text="Property to rent" />
              </Col>
            </Row>
          </Col> */}
        </Row>
      </Container>
          {/* {JSON.stringify(props.data?.id)} */}
      <Carousel
        className="home-properties__collection"
        data={propertiesCollection}
        controllers={{ buttons: isMobile && propertiesCollection?.length > 1 ? true : !isMobile && propertiesCollection?.length > 4 ? true : false }}
        isFluidContainer={false}
      />
       <Modal className="teamdetails popupForm" backdrop="static" size="lg" show={showForm} onHide={() => { setShowForm(false);setIndex() }}>
                                            <Modal.Header closeButton>
                                                <Modal.Title> <div className="form__head">
          <h4>Enquire about Property</h4>
          {/* {JSON.stringify(index)}
          {index?.searchtype} */}
        </div>
</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body> 
                                                <PropertyEnquireForm
                                                id={index?.id}
                                                 property_img={index?.images && index?.images[0]?.url}
                                                  page_url={`${process.env.GATSBY_SITE_URL}${index?.search_type === "sales" && index?.department === "residential" ? "property-for-sale/" : "property-to-rent/"}${index?.slug}-${index?.id}`}
                                                   property_title={index?.display_address}
                                                    type={index?.search_type}
                                                data={index}
                                                />  
                                            </Modal.Body>
</Modal>
       
    </div>
  )
}

export default AuctionSimilarProperties
