/**
 * External dependencies
 */
import React from "react"

/**
 * Internal dependencies
 */

import Layout from "@components/layout"
import Seo from "@components/seo"
import { Col, Container, Row } from "react-bootstrap";
import PropertyInfo from "@components/property-info/property-info"
import PropertySlider from "@components/property-slider/property-slider"
import { currencyFormat } from "@components/common/utils";
import Breadcrumbs from "@components/breadcrumbs/breadcrumbs"
import { useQuery } from '@apollo/client';
import logoBlack from "../images/svg/logo.svg"
import NotFoundPage from "../pages/404"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { navigate } from "@reach/router"
import gql from "graphql-tag"
import AuctionSimilarProperties from "../components/home-properties/auction-similar-properties";
import PropertyAreaGuide from "../components/PropertyAreaGuide/PropertyAreaGuide";
import AuctionPropertyActions from "../components/property-actions/auction-property-actions";
import LoadExternalScript from "../components/utils/load-external-script";

const PROPERTY_DETAILS = gql`
  query GetProperty ($id: String!){  
    properties(where:{id: $id, publish:true}) {
      id
      crm_id
      extra
      title
      department
      introtext
      office_crm_id
      display_address
      price_qualifier
      available_from
      price
      images
      imagetransforms
      accomadation_summary
      description
      long_description
      epc
      latitude
      longitude 
      search_type
      address
      bedroom
      bathroom
      building  
      floorplan
      virtual_tour
      brochure
      floorarea_min
      floorarea_type
      crm_negotiator_id
      status
    }
  }
`;
const AuctionsDetails = (props) => {
  const pageurl = typeof window !== 'undefined' ? window.location.href : ''
  let myid_filt = pageurl.split('?');
  let mypageurl = myid_filt[0];
  let property_slug = mypageurl.split('-');
  let id = property_slug[property_slug.length - 1];
  id = id.replace("/", "");

  // debugger

  const { loading, error, data: property_details } = useQuery(PROPERTY_DETAILS, { variables: { id: id }, fetchPolicy: "no-cache" });

  // console.log(property_details)



  var properties = null;
  if (property_details?.properties?.length > 0) {
    properties = property_details?.properties[0];
  }
  // console.log(properties)
  // if (properties === null) {
  //     navigate('/property/for-sale/')
  // }
  var action = "for sale";
  if (properties?.search_type == "lettings") {
    action = "to rent";
  }

  var metaTitle = `${properties?.title} in ${properties?.display_address}`
  var metaDesc = `Know the details of ${properties?.title} in ${properties?.display_address} with Strakers at ${currencyFormat(properties?.price, '£', false)}. Book a viewing to get assistance in finding the right ${properties?.building} for you.`
  var searchData = {
    action: action,
    areas: (properties?.address?.town) ? properties?.address?.town : properties?.address?.area,
    bedrooms: null,
    defaultArea: (properties?.address?.town) ? properties?.address?.town : properties?.address?.area,
    maxPrice: null,
    minPrice: null,
    propertyType: null,
    search_type: properties?.search_type
  }

  if (loading)
    return (
      <div className="static-news gql-loading-screen">
        <Container>
          <div className="gql-loading">
            <img
              className="logo-white loader-logo"
              src={logoBlack}
              alt="logo"
            />
          </div>
        </Container>
      </div>
    )

  return (
    property_details?.properties?.length > 0 ?
      <Layout propertyClass={properties?.status !== "Sold" ? "property-details-template" : ""}>
        <LoadExternalScript
          src="https://widget.eigonlineauctions.com/loader.js"
          defer="true"
          id="eig-online"
          type="text/javascript"
          // loadScript={renderMap}
          appendScriptTo="body"
        />

        <Seo title="Property" />
        <Breadcrumbs data={properties} Page={`${properties?.display_address} ${(properties?.address?.town) ? (properties?.address?.town === undefined ? "" : ", " + properties?.address?.town) : (properties?.address?.area === undefined ? "" : ", " + properties?.address?.area)}`}
          alias={properties?.search_type} type={"auction-property-details-page"}
          social={true} />
        <PropertySlider data={properties} />
        <PropertyInfo data={properties} />
        {properties?.status !== "Sold" ?
          <AuctionPropertyActions data={properties} />
          : null}
        <AuctionSimilarProperties data={properties} />
        <PropertyAreaGuide data={properties} />
      </Layout>

      : (
        <Layout className="blog-page">
          <Router>
            <Switch>
              <Route component={NotFoundPage} />
            </Switch>
          </Router>
        </Layout>
      )
  )

}

export default AuctionsDetails 