import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "gatsby";
import './assets/styles/_index.scss';
import areaImg from "../../images/property-area-guide.png";
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import ContentBlock from "../content/Content";
import ImageTransform from "../../components/common/ggfx-client/module/components/image-transform"
import {
    GUIDE_PAGE_URL
  } from "../../components/common/site/constants"
const PropertyAreaGuide = (props) => {
    
  const GET_OFFICE_DETAIL = gql`
  query GetAreaguideDetail($URL: String!) {
    areaGuides(where: { Office_Crm_Id: $URL }) {
        id
        imagetransforms  
        Title
      Banner_Image{
        url
        alternativeText
      }
      URL
      Banner_Content     
    }
  }
`

const { loading, error, data } = useQuery(GET_OFFICE_DETAIL, {
    variables: { URL: props?.data?.office_crm_id },
  })

  let processedImages = JSON.stringify({})
  if (data?.areaGuides[0]?.imagetransforms?.Banner_Image_Transforms) {
    processedImages = data?.areaGuides[0]?.imagetransforms.Banner_Image_Transforms
  }


    return (
        data?.areaGuides?.length > 0 ?
        <section className="property-areaguide-wrapper">
            <Container>
                <Row>
                    <Col lg={5} className="order-lg-1 order-2 d-flex align-items-center">
                        <div className="area-guide-content-wrapper">
                            <h5 className="area-guide-heading">{data?.areaGuides[0]?.Title} area guides</h5>
                            <ContentBlock Content={data?.areaGuides[0]?.Banner_Content} />
                            <div className="area-guide-btn-wrapper">
                                <Link to={GUIDE_PAGE_URL.alias+'/'+data?.areaGuides[0]?.URL} className="btn btn--yellow d-flex align-items-center justify-content-center">View Area Guides</Link>
                            </div>
                        </div>
                    </Col>
                    <Col lg={1} className="order-lg-2"></Col>
                    <Col lg={6} className="order-lg-3 order-1">
                        <div className="area-guide-img-wrapper">
                        <ImageTransform
                        imagesources={data?.areaGuides[0]?.Banner_Image?.url}
                        renderer="srcSet"
                        imagename="area-guide.Banner_Image.propimg"
                        attr={{ alt: `${data?.areaGuides[0]?.Title} area guides`, class:'' }}
                        imagetransformresult={processedImages}
                        id={data?.areaGuides[0]?.id}
                      />
                            {/* <img src={areaImg} /> */}
                            {/* <VideoPlaceholder
          image={data?.areaGuides[0]?.Banner_Image}
          processedImages={processedImages}
          id={data?.areaGuides[0]?.id}
        //   video={video}
          alt={`${data?.areaGuides[0]?.Title} area guides`}
          href={GUIDE_PAGE_URL.alias+'/'+data?.areaGuides[0]?.URL}
        //   playBtnOnRight={playBtnOnRight}
          imgname={"area-guide.Banner_Image.propimg"}
        /> */}
                         </div>
                    </Col>
                </Row>
            </Container>
        </section>
    : null)
}

export default PropertyAreaGuide