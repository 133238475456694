/**
 * External dependencies
 */
import React, {useState} from "react"
import { Container, Row, Col } from "react-bootstrap"

/**
 * Internal dependencies
 */
import Button from "../button/button"
import "./styles/_index.scss"
import BookAViewingForm from "../../Forms/BookAViewing"
import Modal from 'react-bootstrap/Modal';
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
const PropertyActions = (props) =>  {
  const [showForm, setShowForm] = useState(false);
  const openform = () => {
          setShowForm(!showForm)
  }
  const shareurl = typeof window !== 'undefined' ? window.location.href : ''

  const GET_OFFICE_DETAIL = gql`
  query GetOfficeDetail($URL: String!) {
    offices(where: { URL: $URL }) {
      id
      imagetransforms
      Office_Name
      Tail_Image {
        url
        alternativeText
      }
      Office_Address
      Office_Email
      URL
      Office_Telephone_Sales
      Office_Telephone_Lettings
      Office_Telephone_Progression
     
    }
  }
`
  const { loading, error, data } = useQuery(GET_OFFICE_DETAIL, {
    variables: { URL: props?.data?.office_crm_id },
  })

  // let processedImages = JSON.stringify({});
  // if (data?.imagetransforms?.images_Transforms) {
  //   processedImages = data?.imagetransforms?.images_Transforms;
  // }
  return(

  (props.data.status!=="Sold Subject To Contract"|| (data?.offices?.length > 0 && data?.offices[0]))&&
  <Container
    fluid="hg"
    className="property-actions d-lg-none position-sticky w-100 start-0 bottom-0 bg-grey"
  >
    <Row className="g-0">
    {props.data.status!=="Sold Subject To Contract"&&
      <Col>
        {/* <Button displayAsLink={false} color="yellow"  onClick={openform}>
          <Button.Icon>
            <i className="icon icon--mail"></i>
          </Button.Icon>

          <Button.Text className="d-md-none">Book a Viewing</Button.Text>
          <Button.Text className="d-none d-md-inline-block">
            Book a Viewing
          </Button.Text>
        </Button> */}
        {props?.data?.extra?.legalDocumentUrl &&
         <Button isExternalLink={true} link={props?.data?.extra?.legalDocumentUrl}>
         <Button.Icon>
           <i className="icon icon--file"></i>
         </Button.Icon>
         <Button.Text>Legal Docs</Button.Text>
          </Button>
        }
      </Col>
    }
      {data?.offices?.length > 0 && data?.offices[0] || props?.data?.extra?.contacts?.length > 0 ?
      <Col>
        <Button tel={data?.offices[0]?.Office_Telephone_Sales ? data?.offices[0]?.Office_Telephone_Sales : props?.data?.extra?.contacts?.length > 0 ? props?.data?.extra?.contacts[0]?.Telephone : ""}>
          <Button.Icon>
            <i className="icon icon--phone"></i>
          </Button.Icon>

          <Button.Text className="d-md-none">Call</Button.Text>
          <Button.Text className="d-none d-md-inline-block">
            {data?.offices[0]?.Office_Telephone_Sales ? data?.offices[0]?.Office_Telephone_Sales : props?.data?.extra?.contacts?.length > 0 ? props?.data?.extra?.contacts[0]?.Telephone : ""}
          </Button.Text>
        </Button>
      </Col>
      : null
      }
    </Row>
    <Modal className="teamdetails popupForm" backdrop="static" size="lg" show={showForm} onHide={() => { setShowForm(false) }}>
                                            <Modal.Header closeButton>
                                                <Modal.Title> <div className="form__head">
          <h4>Book A Viewing</h4>
        </div>
</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body> 
                                                <BookAViewingForm
                                                id={props.data?.id}
                                                to_admin={data?.offices[0]?.Office_Email?.toLowerCase()}
                                                 property_img={props.data?.images[0]?.url}
                                                  page_url={shareurl}
                                                   property_title={props.data?.display_address}
                                                    type={props.data?.search_type}
                                                data={props?.data}/>  
                                            </Modal.Body>

                                        </Modal>
  </Container>
)
  }
export default PropertyActions
